<template>
  <div class="top">
    <section id="Mainvisual" class="mainvisual">
      <span class="mainvisual_line-top"></span>
      <span class="mainvisual_line-bottom"></span>
      <h1 class="mainvisual_message">
        作って終わりにしない、<br />
        継続的な<em><span>運用・機能拡張</span></em
        ><br />
        までサポート
      </h1>
      <div class="mainvisual_scroll">
        <a href="#About" class="mainvisual_scroll_btn">SCROLL</a>
      </div>
    </section>
    <section id="About" class="about">
      <div class="section_contents">
        <h2 class="section_heading">
          <span>ABOUT</span>
          <p>組織紹介</p>
        </h2>
        <div class="section_description">
          <h3 class="section_description_title">
            優秀なエンジニアが集まるエンジニア組織
          </h3>
          <p class="section_description_comment">
            <span class="section_description_comment_main">GILLEとは？</span>
            <span class="section_description_comment_sub"
              >スウェーデン語でギルドの意味</span
            >
          </p>
          <p>ギルドをベースとした自由なエンジニア組織を目指しています。</p>
          <p>
            新人エンジニアのプロジェクトには開発をサポートし、マネジメントする先輩エンジニアが付きます。
          </p>
          <p>
            質を担保しつつ、ナレッジと技術を共有していくギルドの手法を受け継ぎ、現代らしく自由でフラットな組織です。
          </p>
        </div>
        <img
          class="section_img about_img"
          src="@/assets/images/top/about_img.png"
          width="339"
          height="220"
        />
      </div>
    </section>
    <section id="Mission" class="mission">
      <div class="section_contents">
        <h2 class="section_heading">
          <span>MISSION</span>
          <p>ミッション</p>
        </h2>
        <img
          class="section_img mission_img"
          src="@/assets/images/top/mission_img.png"
          width="339"
          height="220"
        />
        <div class="section_description">
          <h3 class="section_description_title">
            作って終わりにしない、継続的な運用・機能拡張までサポート
          </h3>
          <dl class="mission_list">
            <dt class="mission_list_title">Marketing・Planning</dt>
            <dd class="mission_list_item">
              マーケット/貴社事業/生活者の3方を理解し、目的達成のための<span>サービスデザイン・戦略</span>をご支援
            </dd>
            <dt class="mission_list_title">Technology</dt>
            <dd class="mission_list_item">
              IT/テクノロジーの観点で目的達成に<span>最適な開発・業務</span>をご支援
              <ul>
                <li>インフラ構築</li>
                <li>バックエンドアプリケーション</li>
                <li>データベース設計</li>
                <li>ログ基盤</li>
                <li>リソースモニタリング</li>
                <li>データ分析基盤戦略</li>
              </ul>
            </dd>
            <dt class="mission_list_title">Creative</dt>
            <dd class="mission_list_item">
              クリエイティブ思考を持って<span>最適な体験設計</span>をご支援
              <ul>
                <li>フロントエンドアプリ開発</li>
                <li>UX設計</li>
                <li>グラフィックデザイン</li>
                <li>UIデザイン</li>
                <li>webページコーディング</li>
                <li>SEO施策</li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>
    </section>
    <section id="Service" class="service">
      <div class="section_contents">
        <h2 class="section_heading">
          <span>SERVICE</span>
          <p>提供体制・サービス</p>
        </h2>
        <picture class="section_img">
          <source
            srcset="@/assets/images/top/service_img_pc.png"
            media="(min-width: 1096px)"
          />
          <img
            class="section_img service_img"
            src="@/assets/images/top/service_img_sp.png"
            width="322"
            height="48"
          />
        </picture>
        <div class="section_description">
          <h3 class="section_description_title">
            自律的開発ギルドGILLEとエンジニア団体Volare Tokyoの協力体制
          </h3>
          <dl class="service_list">
            <dt class="service_list_title gille">
              <span class="service_list_title_main">GILLE</span
              ><span class="service_list_title_sub">自律的開発ギルド</span>
            </dt>
            <dd class="service_list_item">
              案件管理など、ディレクション業務から開発・運用を支援
            </dd>
            <dt class="service_list_title volare">
              <span class="service_list_title_main">Volare Tokyo</span
              ><span class="service_list_title_sub">エンジニア団体</span>
            </dt>
            <dd class="service_list_item">
              メガベンチャーや大手所属のエンジニアが多く在籍<br />
              ハッカソン出場などの活動
            </dd>
          </dl>
        </div>
      </div>
    </section>
    <section id="Contact" class="contact">
      <div class="section_contents">
        <h2 class="section_heading">
          <span>CONTACT</span>
          <p>お問い合わせ</p>
        </h2>
        <a :href="contactFormLink" target="_blank" class="contact_btn"
          >お問い合わせページへ</a
        >
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Top",
  props: {
    contactFormLink: String,
  },
};
</script>

<style lang="scss" scoped>
.mainvisual {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: url("@/assets/images/top/mv_logo.svg") no-repeat center center/96%;
  min-height: calc(80vh - $sp-header-height);
  margin-bottom: 40px;
  @include mq(md) {
    min-height: calc(80vh - $pc-header-height);
    background-size: 760px;
  }
}

.mainvisual_line-top {
  position: absolute;
  top: 0;
  right: 0;
  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 1px;
    background: #ddd;
  }
  &::before {
    top: 80px;
    right: -50px;
    width: 200px;
    transform: rotate(130deg);
  }
  &::after {
    top: 30px;
    right: -20px;
    width: 220px;
    transform: rotate(50deg);
  }
}

.mainvisual_line-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 1px;
    background: #ddd;
  }
  &::before {
    bottom: 80px;
    left: -60px;
    width: 280px;
    transform: rotate(130deg);
  }
  &::after {
    bottom: 100px;
    left: -40px;
    width: 180px;
    transform: rotate(50deg);
  }
}

.mainvisual_message {
  font-size: fz(24);
  line-height: 170%;
  width: $sp-width;
  max-width: $pc-width;
  margin: 0 auto;
  text-shadow: 0 0 20px $white;
  @media screen and (min-width: 375px) {
    font-size: fz(28);
  }
  @include mq(sm) {
    font-size: fz(32);
  }
  @include mq(md) {
    font-size: fz(48);
  }
  @include mq(lg) {
    font-size: fz(54);
  }
  em {
    color: $white;
    position: relative;
    span {
      position: relative;
      z-index: 1;
      text-shadow: 0 0 $primary-black;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      background: #c6b206;
      mix-blend-mode: multiply;
    }
  }
}

.mainvisual_scroll {
  position: absolute;
  transform: rotate(90deg);
  left: 0px;
  bottom: 80px;
  @include mq(md) {
    bottom: 120px;
  }
}

.mainvisual_scroll_btn {
  font-size: fz(14);
  position: relative;
  display: flex;
  align-items: center;
  color: $primary-gray;
  @include mq(md) {
    font-size: fz(20);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: calc(100% + 8px);
    background: $primary-gray;
  }
  &::before {
    width: 40px;
    height: 1px;
    display: inline-flex;
    @include mq(md) {
      width: 60px;
    }
  }
  &::after {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    animation: circleMove 2s ease-in-out infinite;
    @include mq(md) {
      animation: circleMovePC 2s ease-in-out infinite;
    }
  }
}

@keyframes circleMove {
  0% {
    opacity: 0;
    left: calc(100% + 4px);
  }
  30%,
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: calc(100% + 44px);
  }
}

@keyframes circleMovePC {
  0% {
    opacity: 0;
    left: calc(100% + 8px);
  }
  30%,
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: calc(100% + 68px);
  }
}

.section_contents {
  width: $sp-width;
  margin: 0 auto;
  padding: 40px 0;
  @include mq(md) {
    max-width: $pc-width;
    padding: 100px 0 50px;
  }
  @include mq(lg) {
    display: grid;
  }
  @include mq(xl) {
    width: 100%;
  }
}

.about,
.service {
  .section_contents {
    @include mq(lg) {
      grid-template-areas:
        "areaA areaC"
        "areaB areaC";
      grid-template-columns: 600px 1fr;
    }
  }
}

.mission,
.contact {
  .section_contents {
    @include mq(lg) {
      grid-template-areas:
        "areaC areaA"
        "areaC areaB";
      grid-template-columns: 1fr 600px;
    }
  }
}

.section_img {
  display: block;
  margin: 0 auto 28px;
  @include mq(lg) {
    grid-area: areaC;
  }
}

$sp-h2-mb: 28px;
$pc-h2-mb: 60px;

.section_heading {
  font-size: fz(28);
  font-weight: bold;
  position: relative;
  margin-bottom: $sp-h2-mb * 2;
  height: fit-content;
  @include mq(md) {
    font-size: fz(48);
    margin-bottom: $pc-h2-mb * 2;
  }
  @include mq(lg) {
    grid-area: areaA;
  }
  p {
    position: relative;
  }
  span {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: fz(56);
    font-weight: bold;
    color: $primary-light-gray;
    @include mq(md) {
      font-size: fz(120);
    }
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -($sp-h2-mb);
    width: 40px;
    height: 6px;
    background: $accent;
    @include mq(md) {
      width: 60px;
      height: 8px;
      bottom: -($pc-h2-mb);
    }
  }
}

.section_description {
  @include mq(lg) {
    grid-area: areaB;
  }
  p,
  dd {
    font-size: fz(14);
    color: $primary-dark-gray;
    margin-bottom: 14px;
    @include mq(md) {
      font-size: fz(18);
      margin-bottom: 18px;
    }
  }
  p:last-child {
    margin-bottom: 0;
  }
}

.section_description_title {
  font-size: fz(18);
  font-weight: bold;
  margin-bottom: 28px;
  @include mq(md) {
    font-size: fz(28);
    margin-bottom: 40px;
  }
}

.section_description_comment {
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  &::before {
    content: "";
    mask: url("@/assets/images/top/icon_comment.svg") no-repeat center
      center/100%;
    width: 16px;
    height: 16px;
    background: $primary-black;
    margin-right: 4px;
    @include mq(md) {
      width: 22px;
      height: 22px;
      margin-right: 6px;
    }
  }
}

.section_description_comment_main {
  font-size: fz(16);
  font-weight: bold;
  margin-right: 4px;
  position: relative;
  color: $primary-black;
  display: flex;
  align-items: center;
  @include mq(md) {
    font-size: fz(22);
    margin-right: 6px;
  }
  &::after {
    content: "";
    width: 20px;
    height: 1px;
    background: $primary-gray;
    display: inline-flex;
    margin-left: 4px;
    @include mq(md) {
      width: 40px;
      margin-left: 6px;
    }
  }
}

.section_description_comment_sub {
  font-size: fz(14);
  @include mq(md) {
    font-size: fz(18);
  }
}

.about_img {
  width: calc(100% - 24px);
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: -3vw;
  margin-top: 28px;
  box-shadow: $shadow-s;
  @include mq(md) {
    box-shadow: $shadow;
  }
}

.mission_img {
  width: 100%;
  max-width: 400px;
}

.service_img {
  width: 100%;
  max-width: 400px;
}

.mission_list_title {
  font-size: fz(18);
  font-weight: bold;
  display: inline-block;
  background: linear-gradient(transparent 50%, $accent 0%);
  margin-bottom: 8px;
  @include mq(md) {
    font-size: fz(22);
    margin-bottom: 12px;
  }
}

.mission_list_item {
  span {
    border-bottom: 1px solid $primary-dark-gray;
  }
  ul {
    list-style: disc;
    padding-left: 20px;
    @include mq(md) {
      padding-left: 30px;
    }
  }
}

.service_list_title {
  font-size: fz(18);
  font-weight: bold;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  @include mq(md) {
    font-size: fz(22);
    margin-bottom: 12px;
  }
  &::before {
    content: "";
    width: 4px;
    height: 28px;
    margin-right: 8px;
    background: $primary-gray;
    @include mq(md) {
      height: 32px;
      margin-right: 12px;
    }
  }
  &.gille::before {
    background: linear-gradient(0deg, $primary-gradient);
  }
  &.volare::before {
    background: linear-gradient(0deg, #59b2ce, #54b169);
  }
}

.service_list_title_main {
  display: flex;
  align-items: center;
  margin-right: 6px;
  @include mq(md) {
    margin-right: 8px;
  }
  &::after {
    content: "";
    width: 20px;
    height: 1px;
    background: $primary-gray;
    display: inline-flex;
    margin-left: 6px;
    @include mq(md) {
      width: 40px;
      margin-left: 8px;
    }
  }
}

.contact_btn {
  @include btn(linear-gradient(90deg, $primary-gradient), $white, 20);
  box-shadow: $shadow-s;
  @include mq(md) {
    @include btn(linear-gradient(90deg, $primary-gradient), $white, 32);
    box-shadow: $shadow;
    max-width: 500px;
  }
  @include mq(lg) {
    grid-area: areaB;
  }
}
</style>
