<template>
  <transition name="menuTransition" appear>
    <div class="menu" v-show="isOpenMenu">
      <div class="menu_contetns">
        <ul class="menu_list">
          <li class="menu_list_item">
            <a @click="$emit('closeMenu')" href="#About">ABOUT</a>
          </li>
          <li class="menu_list_item">
            <a @click="$emit('closeMenu')" href="#Mission">MISSION</a>
          </li>
          <li class="menu_list_item">
            <a @click="$emit('closeMenu')" href="#Service">SERVICE</a>
          </li>
          <li class="menu_list_item">
            <a @click="$emit('closeMenu')" href="#Contact">CONTACT</a>
          </li>
        </ul>
        <p class="menu_copyright">Copyright© 2022 GILLE. All Right Reserved.</p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Menu",
  props: {
    isOpenMenu: Boolean,
  },
};
</script>
