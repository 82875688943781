<template>
  <header class="header">
    <Menu :isOpenMenu="isOpenMenu" @closeMenu="isOpenMenu = false" />
    <div class="header_contents">
      <h1 :class="['header_logo', { open: isOpenMenu }]">
        <router-link to="/">
          <Logo />
        </router-link>
      </h1>
      <!-- ~768px -->
      <button class="header_menu" @click="toggleIsOpenMenu">
        <div :class="['header_menu_icon', { open: isOpenMenu }]">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
      <!-- 768px~ -->
      <nav class="header_nav">
        <ul class="header_nav_list">
          <li class="header_nav_list_item"><a href="#About">ABOUT</a></li>
          <li class="header_nav_list_item"><a href="#Mission">MISSION</a></li>
          <li class="header_nav_list_item"><a href="#Service">SERVICE</a></li>
          <li class="header_nav_list_item"><a href="#Contact">CONTACT</a></li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import Menu from "./Menu";
import Logo from "./Logo";

export default {
  name: "Header",
  components: {
    Menu,
    Logo,
  },
  data() {
    return {
      isOpenMenu: false,
    };
  },
  methods: {
    toggleIsOpenMenu() {
      this.isOpenMenu = !this.isOpenMenu;
    },
  },
};
</script>
