<template>
  <footer class="footer">
    <div class="footer_contents">
      <span class="footer_copyright"
        >Copyright© 2022 GILLE. All Right Reserved.</span
      >
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
