<template>
  <div class="wrapper">
    <Header />
    <main class="main">
      <router-view :contactFormLink="contactFormLink" />
    </main>
    <Footer />
    <ContactBtn :contactFormLink="contactFormLink" />
  </div>
</template>

<script>
import Header from "@/components/Common/Header";
import Footer from "@/components/Common/Footer";
import ContactBtn from "@/components/Common/ContactBtn";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    ContactBtn,
  },
  data() {
    return {
      contactFormLink: "https://forms.gle/pabtNQYzPF4bi2757",
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/app.scss";
</style>
