<template>
  <div class="contactBtn-wrapper">
    <a :href="contactFormLink" target="_blank" class="contactBtn">
      <span class="contactBtn_icon" />
      <p class="contactBtn_inner-text" />
    </a>
    <p class="contactBtn_outer-text" />
  </div>
</template>

<script>
export default {
  name: "ContactBtn",
  props: {
    contactFormLink: String,
  },
};
</script>

<style lang="scss" scoped>
.contactBtn-wrapper {
  position: fixed;
  bottom: 20px;
  right: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mq(md) {
    bottom: 40px;
    right: 40px;
  }
}

.contactBtn {
  @include btn(linear-gradient(90deg, $primary-gradient), $white, 20);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  color: $white;
  box-shadow: $shadow-s;
  @include mq(md) {
    width: fit-content;
    height: fit-content;
    border-radius: 100px;
    padding: 20px 48px;
  }
}

.contactBtn_icon {
  position: relative;
  left: -1px;
  mask: url("@/assets/images/top/icon_contact.svg") no-repeat center center/100%;
  width: 24px;
  height: 24px;
  background: $white;
  @include mq(md) {
    margin-right: 8px;
  }
}

.contactBtn_inner-text,
.contactBtn_outer-text {
  mask: url("@/assets/images/top/text_contact.svg") no-repeat center center/100%;
}

.contactBtn_inner-text {
  display: none;
  @include mq(md) {
    display: inline-block;
    background: $white;
    width: 120px;
    height: 24px;
  }
}

.contactBtn_outer-text {
  background: linear-gradient(90deg, $primary-gradient);
  width: 60px;
  height: 12px;
  margin-top: 4px;
  @include mq(md) {
    display: none;
  }
}
</style>
